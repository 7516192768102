
.login-input {
    background-color: #F3E5F5;
    border-radius: 10px !important;
    padding: 12px 15px 12px 15px !important;
    width: 100%;
    box-sizing: border-box;
    border: none !important;
    border: 1px solid #F3E5F5 !important;
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 400
}

.login-input:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #da990d !important;
    outline-width: 0;
    font-weight: 400
    
}

.logo-image{
  height: 15vw;
  padding: 1vw;
}

button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0
}

.card1 {
    width: 50%;
    padding: 40px 30px 10px 30px;
    border-radius: 0%;
}

.card2 {
    width: 50%;
    background-image: linear-gradient(to right, #000000, #000000);
    border-radius: 0%;
}

#logo {
  width: 90%;
}

.heading {
    margin-bottom: 60px !important
}

::placeholder {
    color: rgb(133, 133, 133) !important;
    opacity: 1
}

:-ms-input-placeholder {
    color: rgb(70, 70, 70) !important
}

::-ms-input-placeholder {
    color: rgb(70, 70, 70) !important
}

.form-control-label {
    font-size: 12px;
    margin-left: 15px
}

.msg-info {
    padding-left: 15px;
    margin-bottom: 30px
}

.btn-color {
  border-radius: 50px;
  color: black;
  background-color: #F4D465;
  padding: 15px;
  cursor: pointer;
  border: none !important;
  margin-top: 40px;
  border-radius: 10px;
}.btn-color:hover {
    box-shadow: 0px 4px 14px 5px rgb(209, 209, 209);
}.btn-color:active{
  transform: scale(0.95);
  transition: transform 0.5s;
}

.btn-white {
    border-radius: 50px;
    color: #D500F9;
    background-color: #fff;
    padding: 8px 40px;
    cursor: pointer;
    border: 2px solid #D500F9 !important
}

.btn-white:hover {
    color: #fff;
    background-image: linear-gradient(to right, #FFD54F, #D500F9)
}

a {
    color: #000
}

a:hover {
    color: #000
}

.bottom {
    width: 100%;
    margin-top: 50px !important
}

.sm-text {
    font-size: 15px
}

@media screen and (max-width: 992px) {
    .card1 {
        width: 100%;
        padding: 40px 30px 10px 30px
    }

    .card2 {
        width: 100%
    }

    .right {
        margin-top: 100px !important;
        margin-bottom: 100px !important
    }
}

@media screen and (max-width: 768px) {
    .container {
        padding: 10px !important
    }

    .card2 {
        padding: 50px
    }

    .right {
        margin-top: 50px !important;
        margin-bottom: 50px !important
    }

    .logo-image{
        height: 20vw;
    }
}