.logos-cont{
    flex: 0 1 50%;
    justify-self: start;
    padding: 1vw;
 }
 
 .bold-orng{
     font-weight: bold;
     color: rgb(209, 136, 0);
 }
 
 .parent {
     display: flex;
     width: 100%;
     height: fit-content;
     align-items: center; /* Align Items Vertically */
     justify-content: space-between; /* Align Items Horizontally (with equal space in between each of them */
     background: rgb(245, 245, 245);
 }
 
 .footer-images{
     width: 19vw;
     height: auto;
     margin-top: 1vh;
 }
 
 .small-font{
     font-size: 1.2vw;
 }
 
 .small-imgs{
     width: 8vw;
     height: auto;
 }
 
 .small-black{
     font-size: 1vw;
     color: black;
     margin-bottom: 4vh;
 }
 
 .iso-text{
     font-weight: bold;
 }
 
 .light-orng-border{
     border-bottom: 1.4vh solid #ff5622a6;
 }
 
 .orng-border{
     border-bottom: 1.4vh solid #ff5722;
     /* margin-right: 20px;
     margin-left: 20px; */
 }
 
 .bottom-text{
     font-size: 1.2vw;
 }
 
 .small-bottom-text{
     font-size: 0.8vw;
     margin-bottom: 2.5vh;
 }
 
 .small-bottom-text1{
     margin-top: 1vh;
 }
 
 .gg-erp-color{
     color: #ff5722;
 }
 
 @media only screen and (max-width: 768px){
     .small-imgs{
         width: 14.5vw
     }
 
     .bottom-text{
         font-size: 15px;
     }
 
     .small-font{
         font-size: 2.5vw;
     }
 
     .footer-images{
         width: 30vw;
     }
 
     .small-black{
         font-size: 2.0vw;
     }
 
     .small-bottom-text{
         font-size: 2.5vw;
     }
 }
 
 @media only screen and (max-width: 600px){
     .small-imgs{
         width: 14.5vw
     }
 
     .bottom-text{
         font-size: 15px;
     }
 
     .small-font{
         font-size: 2.5vw;
     }
 
     .footer-images{
         width: 30vw;
     }
 
     .small-black{
         font-size: 6px;
     }
 
     .small-bottom-text{
         font-size: 2.5vw;
     }
 
}