/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Poppins;
    src: url(./fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: Nunito;
  src: url('./fonts/NunitoSans-Black.ttf');
}
  
body {
    font-family: Poppins;
}

.gg-erp-color{
  color: #ff5722;
}

.bg-orng{
  background-color: #ff5722;
}

.form-input{
  @apply  ring-red-500 box-border text-black bg-white w-full p-2 rounded-md
}

.submit{
  @apply p-2 my-2 text-white rounded-md bg-orng
}

.export {
  @apply bg-green-600 rounded-md p-2 text-white 
}

input{
  border: none;
  border: 1px solid rgb(214, 214, 214);
}

input:focus{
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
  font-weight: 400;
  border: 2px solid #ff5722;
}
textarea:focus{
  border: none;
}
textarea{
  @apply w-full ring-1 h-16 focus:ring focus:ring-red-400 p-1
}

.box_1{
  background: #eee;
}

input[type="checkbox"].switch_1{
  font-size: 30px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 3.5em;
  height: 1.5em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
  
input[type="checkbox"].switch_1:checked{
  background: #0ebeff;
}
  
input[type="checkbox"].switch_1:after{
  position: absolute;
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 .25em rgba(0,0,0,.3);
          box-shadow: 0 0 .25em rgba(0,0,0,.3);
  -webkit-transform: scale(.7);
          transform: scale(.7);
  left: 0;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
  
input[type="checkbox"].switch_1:checked:after{
  left: calc(100% - 1.5em);
}